// import './App.css';
import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';


import "../src/Perfectwebcss/App.css"
import "../src/Perfectwebcss/Responsive.css"

import "../src/MarketingCss/style.css";
import "../src/MarketingCss/responsive.css";

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Marketing from './Components/real-estate-web-solutions-dubai.jsx';
import Seoplumber from './Components/seo-for-plumbers.jsx';
import Thankyou from './Components/Thankyou.jsx';
import Homepage from './Components/Homepage.jsx';
import NotFoundPage from './Components/NotFoundPage';

function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/seo-for-plumbers" element={<Seoplumber />} />
        <Route path="/real-estate-web-solutions-dubai" element={<Marketing />} />
        <Route path="/thankyou" element={<Thankyou />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App



import React from 'react'
import SliderShapeone from "../Images/slider-shape.svg"
import SliderShapetwo from "../Images/slider-shape-two.svg"
const Banner = () => {
    return (
        <div>

            <section className="main-slider-one">
                <div className="main-slider-one__carousel ostech-owl__carousel owl-carousel" >
                    <div className="item">
                        <div className="main-slider-one__item">
                            <div className="main-slider-one__bg">
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 d-flex align-items-center justify-content-center">
                                        <div className="main-slider-one__content">
                                            <h5 className="main-slider-one__sub-title"><span>Efficient Solutions, Exceptional Results</span></h5>
                                            <h2 className="main-slider-one__title">
                                            Unlock Your Success With Advanced<br />
                                                <span> <b className='banner-b'>IT</b> Solutions</span>
                                                <span className="main-slider-one__title__overlay-group">
                                                    <span className="main-slider-one__title__overlay"></span>
                                                    <span className="main-slider-one__title__overlay"></span>
                                                    <span className="main-slider-one__title__overlay"></span>
                                                    <span className="main-slider-one__title__overlay"></span>
                                                    <span className="main-slider-one__title__overlay"></span>
                                                    <span className="main-slider-one__title__overlay"></span>
                                                </span>
                                            </h2>
                                            <div className="main-slider-one__link">
                                                <a href="#contactus" className="main-slider-one__link__btn ostech-btn">Contact Us</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="main-slider-one__shape main-slider-one__shape--one">
                                <img src={SliderShapeone} alt="banner-effect-img" />
                            </div>
                            <div className="main-slider-one__shape main-slider-one__shape--two">
                                <img src={SliderShapetwo} alt="banner-effect-img" />
                            </div>
                        </div>
                    </div>

                    <div className="item">
                        <div className="main-slider-one__item">
                            <div className="main-slider-one__bg main-slider-two__bg">
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 d-flex align-items-center justify-content-center">
                                        <div className="main-slider-one__content">
                                            <h5 className="main-slider-one__sub-title"><span>Efficient Solutions, Exceptional Results</span></h5>
                                            <h2 className="main-slider-one__title">
                                            Get Ultimate Business Success with Effective<br />
                                                <span>   Marketing Strategies</span>
                                                <span className="main-slider-one__title__overlay-group">
                                                    <span className="main-slider-one__title__overlay"></span>
                                                    <span className="main-slider-one__title__overlay"></span>
                                                    <span className="main-slider-one__title__overlay"></span>
                                                    <span className="main-slider-one__title__overlay"></span>
                                                    <span className="main-slider-one__title__overlay"></span>
                                                    <span className="main-slider-one__title__overlay"></span>
                                                </span>
                                            </h2>
                                            <div className="main-slider-one__link">
                                                <a href="#contactus" className="main-slider-one__link__btn ostech-btn">Contact Us</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="main-slider-one__shape main-slider-one__shape--one">
                                <img src={SliderShapeone} alt="banner-effect-img" />
                            </div>
                            <div className="main-slider-one__shape main-slider-one__shape--two">
                                <img src={SliderShapetwo} alt="banner-effect-img" />
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="main-slider-one__item">
                            <div className="main-slider-one__bg main-slider-three__bg">
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 d-flex align-items-center justify-content-center">
                                        <div className="main-slider-one__content">
                                            <h5 className="main-slider-one__sub-title"><span>Efficient Solutions, Exceptional
                                                Results</span></h5>
                                            <h2 className="main-slider-one__title">
                                            Ultimate Web Development Services  <br />
                                                <span> Across The World</span>
                                                <span className="main-slider-one__title__overlay-group">
                                                    <span className="main-slider-one__title__overlay"></span>
                                                    <span className="main-slider-one__title__overlay"></span>
                                                    <span className="main-slider-one__title__overlay"></span>
                                                    <span className="main-slider-one__title__overlay"></span>
                                                    <span className="main-slider-one__title__overlay"></span>
                                                    <span className="main-slider-one__title__overlay"></span>
                                                </span>
                                            </h2>
                                            <div className="main-slider-one__link">
                                                <a href="#contactus" className="main-slider-one__link__btn ostech-btn">Contact Us</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="main-slider-one__shape main-slider-one__shape--one">
                                <img src={SliderShapeone} alt="banner-effect-img" />
                            </div>
                            <div className="main-slider-one__shape main-slider-one__shape--two">
                                <img src={SliderShapetwo} alt="banner-effect-img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Banner

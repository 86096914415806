import React from 'react';
import errorimg from "../Images/notfound.svg";

const NotFoundPage = () => {
    return (
        <section className="errro-page">
            <div className="container d-flex justify-space-between ">
        
                <div className="wrapper">
                    <h1>404<br /> Oops!</h1>
                    <p>Page Not Found</p>
                    <a href="/real-estate-web-solutions-dubai" className='back-home-btn'>Back To Home</a>
                </div>
                <div className="img-dvi">
               <img src={errorimg} alt="errorimg" />
               </div>
               
            </div>
        </section>
    );
};

export default NotFoundPage;

import React from 'react'
import { useLocation } from 'react-router-dom';

import Header from "../Components/Header/Header"
import Banner from './Banner'
import Aboutus from "../Components/Aboutus"
import Services from './Services'
import Portfolio from "../Components/PortfolioData"
import Footer from './Footer/Footer'

const Homepage = () => {

    const location = useLocation();

    React.useEffect(() => {
      if (location.pathname === '/') {
        // import("../Perfectwebcss/App.css");
        // import("../Perfectwebcss/Responsive.css");
      }
    }, [location.pathname]);

    return (
        <main className='home-main'>
            <Header></Header>
            <Banner></Banner>
            <Aboutus></Aboutus>
            <Services></Services>
            <Portfolio></Portfolio>
            <Footer></Footer>

        </main>
    )
}

export default Homepage

import React from 'react'
import servicesData from './servicesdata'


const Services = () => {
    return (
        <div className='ourservices' id='ourservices'>
            <div className="container">
                <p className="sub-heading text-center">Our Services</p>
                <h2 className='text-center'>Explore Our Best Premium Quality Service</h2>
                <div className="row">
                {servicesData.map(service => (
                        <div key={service.id} className="col-md-4 col-sm-6">
                            <div className="our-services-box">
                                <div className="services-img">
                                    <img src={service.imgSrc} alt={service.title} />
                                </div>
                                <div className="services-box-data">
                                    <div className="services-icon-contain">
                                        <img src={service.iconSrc} alt={`${service.title} icon`} />
                                    </div>
                                    <h3>{service.title}</h3>
                                    <p>{service.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Services

import React from 'react'
import emailimg from "../marketing-images/email.svg"
import logoimg from "../marketing-images/logo.svg"
const Thankyou = () => {
  return (
    
    <div>
      <div className="header-logo">
<div className="container">
  <div className="row">
    <div className="col-md-12">
    <a href="/"><img src={logoimg} alt="logoimg" /></a>
    </div>
  </div>
</div>
      </div>
      <section className='thankyou'>
            <div className="container">
              <div className="row">
                <div className="col-md-12 d-flex justify-content-center align-items-center" id="thanks-data">
                  <div className="thanks-data">
                    <h1>Thank Y<svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="27" cy="27" r="27" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M27 54C30.5457 54 34.0567 53.3016 37.3325 51.9447C40.6082 50.5879 43.5847 48.5991 46.0919 46.0919C48.5991 43.5847 50.5879 40.6082 51.9447 37.3325C53.3016 34.0567 54 30.5457 54 27C54 23.4543 53.3016 19.9433 51.9447 16.6675C50.5879 13.3918 48.5991 10.4153 46.0919 7.90812C43.5847 5.40093 40.6082 3.41213 37.3325 2.05525C34.0567 0.698375 30.5457 -5.28349e-08 27 0C19.8392 1.06705e-07 12.9716 2.84463 7.90812 7.90812C2.84464 12.9716 0 19.8392 0 27C0 34.1608 2.84464 41.0284 7.90812 46.0919C12.9716 51.1554 19.8392 54 27 54ZM26.304 37.92L41.304 19.92L36.696 16.08L23.796 31.557L17.121 24.879L12.879 29.121L21.879 38.121L24.201 40.443L26.304 37.92Z" fill="#FE5F13"/>
</svg>
u!</h1>
                    <div className="thanks-content">
                      <div>
                        <img src={emailimg} alt="emailicon" />
                      </div>
                      <div>
                      Your submission has been received.<br/>
                      We'll be in touch and contact you soon.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    </section>
    </div>
  )
}

export default Thankyou

// data.js

import uiuxicon from "../Images/ui-ux-icon.svg"
import webdesignicon from "../Images/web-design-icon.svg"
import webdevelopmenticon from "../Images/webdevelopmenticon.svg"
import wordpressicon from "../Images/digital-marketing-icon.svg"
import seoicon from "../Images/reacticon.svg"
import digitalmarketingicon from "../Images/mern.svg"

const servicesData = [
    {
        id: 1,
        title: 'UI/UX Designing',
        description: 'We provide the exceptional user experience by optimizing the areas of product development including branding, usability, function, & design and the overall look of your website.',
        imgSrc: require('../Images/ui-ux-designing.webp'),
        iconSrc: uiuxicon
    },
    {
        id: 2,
        title: 'Web Designing',
        description: 'Dive into the world of top-notch and user-friendly designs with our creative website design services. Get visually stunning design services enhancing the experience.',
        imgSrc: require('../Images/web-design.webp'),
        iconSrc: webdesignicon
    },
    {
        id: 3,
        title: 'Web Development',
        description: 'Take your business to the next level with our full stack development services. Leverage modern frameworks to build your projects.',
        imgSrc: require('../Images/webdevelopment.webp'),
        iconSrc: webdevelopmenticon
    },

    {
        id: 4,
        title: 'Digital Marketing',
        description: 'We offer a range of effective marketing services to help your business reach its target audience and achieve maximum growth.',
        imgSrc: require('../Images/wordpress.webp'),
        iconSrc: wordpressicon
    },
    {
        id: 5,
        title: 'React Frontend',
        description: 'Our team of experts harness the power of the React library that provides scalable, efficient and customized frontend solutions that deliver robust and responsive web applications.',
        imgSrc: require('../Images/react.webp'),
        iconSrc: seoicon
    },
    {
        id: 6,
        title: 'MERN/MEAN',
        description: 'The MERN/MEAN stack is a collection of technologies used to develop full-stack web applications like MongoDB, Express.js, React.js, Angular.js, and Node.js to provide an end-to-end framework. ',
        imgSrc: require('../Images/mern.webp'),
        iconSrc: digitalmarketingicon
    },

];

export default servicesData;
